<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form inline size="small">
          <el-form-item label="日期">
            <el-date-picker
              v-model="conditions.year"
              type="year"
              placeholder="选择年"
              value-format="yyyy"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-radio-group v-model="radio">
              <el-radio :label="1">统计车数</el-radio>
              <el-radio :label="2">统计吨数</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="buttons" @click="handleSearch"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item style="float: right">
            <el-button type="primary" class="buttons" @click="handleCommand"
              >导出</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table">
        <el-table
          border
          :data="tableData"
          style="width: 100%"
          :span-method="objectSpanMethod"
        >
          <el-table-column
            show-overflow-tooltip
            label="省份"
            prop="province"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="流向"
            prop="city"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="车队"
            prop="fleetName"
          ></el-table-column>
          <el-table-column
            v-for="item in 12"
            :key="item"
            show-overflow-tooltip
            :label="item + '月'"
            :prop="'m' + item"
          ></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import mTitle from "@/components/mTitle";
export default {
  data() {
    return {
      conditions: {
        year: new Date().getFullYear() + "",
      },
      radio: 1,
      total: 0,
      statTotal: 0,
      statSum: 0,
      tableData: [],
      showAdd: false,
    };
  },
  // computed: {
  //   ...mapState(["conditionsList"]),
  // },
  components: {
    mTitle,
  },
  created() {
    this.getList();
  },
  methods: {
    // ...mapMutations(["createConditions"]),
    // 获取列表数据
    async getList() {
      if (this.radio == 2) {
        const { data } = await this.$http(
          "api1",
          "/api/stat/flowByTons",
          "get",
          this.conditions
        );
        console.log(data);
        if (data.code != 0) return this.$message.error(data.msg);
        this.tableData = data.data;
      } else {
        const { data } = await this.$http(
          "api1",
          "/api/stat/flowByTrunks",
          "get",
          this.conditions
        );
        console.log(data);
        if (data.code != 0) return this.$message.error(data.msg);
        this.tableData = data.data;
      }
    },
    handleSearch() {
      this.getList();
    },
    getProvince() {
      let result = [];
      for (let i = 0; i < this.tableData.length; i++) {
        for (let j = i + 1; j < this.tableData.length; j++) {
          if (this.tableData[i].city != this.tableData[j].city) {
            result.push({ rowspan: j - i, colspan: 1 });
            break;
          }
        }
      }
    },
    getCity() {},
    async handleCommand() {
      if (this.radio == "统计车数") {
        this.$download("post", "/api/stat/flowByTrunksExport", this.conditions);
      } else {
        this.$download("post", "/api/stat/flowByTonsExport", this.conditions);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
